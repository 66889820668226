import React, { useState } from 'react'

export enum ColorType {
	BLACK = 'BLACK',
	WHITE = 'WHITE',
}

interface DefaultStateType {
	itemColor: ColorType
	invertColors: (color: ColorType) => void
}

const defaultState: DefaultStateType = {
	itemColor: ColorType.BLACK,
	invertColors: () => undefined,
}

const InvertColorsContext = React.createContext(defaultState)

function InvertColorsProvider({ children }) {
	const [color, setColor] = useState<ColorType>(ColorType.BLACK)

	const invertColors = (colorType: ColorType) => {
		if (colorType === ColorType.WHITE) {
			setColor(ColorType.BLACK)
		} else if (colorType === ColorType.BLACK) {
			setColor(ColorType.WHITE)
		}
	}

	return (
		<InvertColorsContext.Provider
			value={{
				itemColor: color,
				invertColors,
			}}
		>
			{children}
		</InvertColorsContext.Provider>
	)
}

export default InvertColorsContext

export { InvertColorsProvider }
