import React from 'react'
import { InvertColorsProvider } from './src/context/InvertColorsContext'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export const wrapRootElement = ({ element }) => (
	<InvertColorsProvider>{element}</InvertColorsProvider>
)
